import { requiredError } from 'Components/Forms/FormErrors'
import { AlertComponent } from 'Components/HelperComponents/AlertComponent'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { EditComponent } from 'NewVersion/components/Fields/EditComponent'
import { SelectInputField } from 'NewVersion/components/Fields/InputSelect'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useFormik } from 'formik'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import React, { Fragment, useMemo } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { get } from 'utils/objectUtils'
import { copyGuillotinaObjectWithoutIds, getCurrentJWToken, getEmailFromToken } from 'utils/utils'
import Button from '../UI/Buttons/Button'

const Form = ({ representant, setArtista, ensTypeObj, toggleInit }) => {
  const { edition, currentEdition, loading } = useEdition()

  const {
    data: entitySchemaData,
    isLoading: entitySchemaLoading,
    error: entitySchemaError,
  } = useGuillotina({
    path: `@types/${ensTypeObj['interface']}`,
  })

  const {
    data: optionsArtistaResult,
    isLoading: optionsArtistaLoading,
    error: optionsArtistaError,
  } = useGuillotina({
    path: `/@search?type_name=Ens&tipus=artista&owners=${getEmailFromToken()}&_sort_des=creation_date`,
  })

  const optionsArtista = useMemo(() => {
    const getResult = (list) => {
      return list.map((ens) => {
        return {
          label: ens.title + ' (' + ens.year + ')',
          value: ens.path,
          tipus: ens.tipus,
          year: ens.year,
        }
      })
    }
    if (optionsArtistaResult) {
      return getResult(optionsArtistaResult.items)
    }
  }, [optionsArtistaResult])

  if (loading || entitySchemaLoading || optionsArtistaLoading) {
    return <LoadingComponent />
  }

  const setArtistArtistForm = async (artist) => {
    const artistValue = artist.value || artist.target.value
    const artistYear = artist.year || artistValue.split('/')[1]
    const token = getCurrentJWToken()
    await fetchGuillotina({
      path: artistValue,
      method: 'GET',
      token,
    })
      .then(async (response) => {
        if (artistYear !== edition) {
          const newArtist = copyGuillotinaObjectWithoutIds(response)
          await fetchGuillotina({
            path: `${edition}`,
            method: 'POST',
            data: newArtist,
            token: token,
          }).then((response) => {
            if (response) {
              const path = '/' + response['@id'].split('/').slice(-2).join('/')
              setArtistArtistForm({
                value: path,
                year: edition,
              })
            }
          })
        } else {
          setArtista(response)
        }
      })
      .catch((error) => {
        console.error(`Artist form error: ${error}`)
      })
  }

  return (
    <ArtistFormContent
      ensTypeObj={ensTypeObj}
      edition={edition}
      toggleInit={toggleInit}
      currentEdition={currentEdition}
      schema={{
        data: entitySchemaData,
        error: entitySchemaError,
        loading: entitySchemaLoading,
      }}
      optionsArtista={{
        data: optionsArtista,
        error: optionsArtistaError,
        loading: optionsArtistaLoading,
      }}
      setArtistArtistForm={setArtistArtistForm}
      representant={representant}
    />
  )
}

const ArtistFormContent = ({
  ensTypeObj,
  edition,
  toggleInit,
  schema,
  optionsArtista,
  setArtistArtistForm,
  representant,
  readOnlyForm = false,
}) => {
  const { t } = useTranslation()
  const [submitError, setSubmitError] = React.useState({
    hasError: false,
    message: '',
  })

  const handleFormError = (error) => {
    console.error(`Artist form error: ${error}`)
    if (error.response && error.response.status === 412) {
      if (get(error, 'response.data.code') === 'already-exists') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('title_already_exists')}`,
        })
      } else if (get(error, 'response.data.code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else if (get(error, 'response.data.code') === 'promo-code-not-exist') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('promo_code_not_exist')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const valuesEntity = {}
    const token = getCurrentJWToken()

    Object.keys(values).forEach((valueKey) => {
      if (valueKey.indexOf('entity_') === 0) {
        valuesEntity[valueKey.replace('entity_', '')] = values[valueKey] || null
      }
    })

    const data = Object.assign({}, valuesEntity, {
      '@type': ensTypeObj['interface'],
      tipus: 'artista',
      tarifa: 'gratuita',
    })

    await fetchGuillotina({
      path: `${edition}`,
      method: 'POST',
      data: data,
      token: token,
    })
      .then((response) => {
        if (response) {
          const path = '/' + response['@id'].split('/').slice(-2).join('/')
          setArtistArtistForm({
            value: path,
            year: edition,
          })
        }
      })
      .catch((error) => {
        handleFormError(error)
        setSubmitting(false)
        return
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const createInitialValue = (representantObj, copyTitle = false) => {
    return {
      entity_tarifa: 'gratuita',
      entity_tipus: 'artista',
      entity_representant: get(representantObj, 'path', null),
      entity_title: representantObj && copyTitle ? representantObj.title : '',
      entity_nom_fiscal: representantObj ? representantObj.nom_fiscal : '',
      entity_nif: representantObj ? representantObj.nif : '',
      entity_adreca: representantObj ? representantObj.adreca : '',
      entity_codi_postal: representantObj ? representantObj.codi_postal : '',
      entity_poblacio: representantObj ? representantObj.poblacio : '',
      entity_pais: representantObj?.pais,
      entity_comunitat_autonoma: representantObj?.comunitat_autonoma,
      entity_comarca: representantObj?.comarca,
      entity_telefon: representantObj ? representantObj.telefon : '',
      entity_mobil: representantObj ? representantObj.mobil : '',
      entity_correu: representantObj ? representantObj.correu : getEmailFromToken(),
      entity_nom_contacte: representantObj ? representantObj.nom_contacte : '',
      entity_idioma: representantObj ? representantObj.idioma : '',
      entity_cognoms_contacte: representantObj ? representantObj.cognoms_contacte : '',
      entity_web: representantObj ? representantObj.web : '',
      entity_facebook: representantObj ? representantObj.facebook : '',
      entity_twitter: representantObj ? representantObj.twitter : '',
      entity_instagram: representantObj ? representantObj.instagram : '',
      entity_linkedin: representantObj ? representantObj.linkedin : '',
      entity_youtube: representantObj ? representantObj.youtube : '',
    }
  }

  const getInitialValues = React.useMemo(() => {
    const result = {}
    ensTypeObj['requiredFields'].forEach((item) => {
      result[`entity_${item}`] = undefined
    })
    const initialValue = createInitialValue(representant)
    return {
      ...result,
      ...initialValue,
    }
  }, [ensTypeObj])

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}
      ensTypeObj['requiredFields'].forEach((key) => {
        if (key !== 'declaracio_acceptar') {
          const keyForm = `entity_${key}`
          if ((!(keyForm in values) || !values[keyForm]) && renderLocationFields(key)) {
            errors[keyForm] = requiredError()
          }
        }
      })

      const keyForm = `entity_correu`
      if (values[keyForm] && !values[keyForm].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        errors[keyForm] = t('invalid_email')
      }

      return errors
    },
  })

  const fieldsWithPrefixLabel = ['idioma', 'correu']

  const getLabel = (key) => {
    if (fieldsWithPrefixLabel.includes(key)) return `ens_${key}`
    return key
  }

  const renderLocationFields = (key) => {
    if (key === 'comunitat_autonoma' && get(formik, 'values.entity_pais', '') !== 'country-ES') {
      return false
    }
    if (
      key === 'comarca' &&
      (get(formik, 'values.entity_pais', '') !== 'country-ES' ||
        get(formik, 'values.entity_comunitat_autonoma', '') !== 'catalunya')
    ) {
      return false
    }
    return true
  }

  const renderFields = (key) => {
    if (key === 'declaracio_acceptar' || key === 'artista') return false
    return renderLocationFields(key)
  }

  const getDescription = (value, key) => {
    /*
    TODO: Refactor de les descripcions dels camps, evitar casos unics i utilitzar el type_name per crear les claus,
    es necessari modificar back per retornar la clau en el camp descripcio per exemple, analitzar-ho bé.
    */
    if (key === 'correu') {
      return t('ens_correu_description')
    }
    if (key === 'pais') {
      return t('pais_multiple_description')
    }
    return value.description ? t(`${key}_description`) : null
  }

  return (
    <>
      {submitError.hasError && submitError.message && (
        <AlertComponent type="danger"> {submitError.message}</AlertComponent>
      )}

      <h4>
        {t('presentar_proposta_artista')} {edition}
      </h4>

      {representant && (
        <div className="Form">
          <SelectInputField name="representant" label={representant.title} options={[]} disabled />
        </div>
      )}

      {!optionsArtista.loading && optionsArtista.data?.length > 0 && (
        <form className="Form" id="selector-artista" data-test="formArtistSelectorTest">
          <h5>{t('escull_artista')}</h5>
          <SelectInputField
            name="artista"
            label={t('Seleccioneu una opcio')}
            onChange={(ev) => {
              formik.setFieldValue('artistSelector', ev)
              formik.validateForm()
            }}
            onBlur={() => {
              formik.setFieldTouched('artistSelector')
            }}
            options={optionsArtista.data}
            error={formik.touched['artistSelector'] && formik.errors['artistSelector']}
            callback={setArtistArtistForm}
            dataTest={'artistSelector'}
          />
        </form>
      )}

      <form
        className="Form"
        onSubmit={(values) => formik.handleSubmit(values)}
        data-test="formTest"
      >
        {schema &&
          schema.data &&
          !schema.loading &&
          ensTypeObj['orderFieldsForm']
            .filter((item) => item.title !== 'declaracio_fieldset')
            .map((item) => {
              return (
                <Fragment key={item['title']}>
                  {item['title'] !== 'entity_data' ? (
                    <h5>{t(item['title'])}&nbsp;</h5>
                  ) : (
                    <h4 className="centre-line">
                      {t('o bé')} {t("crea'n un de nou")}
                    </h4>
                  )}
                  <div className="fragment">
                    {item['fields'].map((key) => {
                      if (renderFields(key) && get(schema, `data.properties[${key}]`, null)) {
                        const value = schema.data.properties[key]
                        const keyForm = `entity_${key}`
                        return (
                          <EditComponent
                            key={keyForm}
                            name={keyForm}
                            label={
                              key === 'title'
                                ? t(getLabel(ensTypeObj['labelName']))
                                : t(getLabel(key))
                            }
                            schema={value}
                            description={getDescription(value, key)}
                            value={formik.values[keyForm]}
                            required={(ensTypeObj['requiredFields'] ?? []).includes(key)}
                            onBlur={() => {
                              formik.setFieldTouched(keyForm)
                            }}
                            onChange={(ev) => {
                              formik.setFieldValue(keyForm, ev)
                              formik.validateForm()
                            }}
                            error={formik.touched[keyForm] && formik.errors[keyForm]}
                            dataTest={`inputFieldTest_${keyForm}`}
                          />
                        )
                      }
                    })}
                  </div>
                </Fragment>
              )
            })}

        <div>
          {formik.isSubmitting && <LoadingComponent />}
          {!readOnlyForm && (
            <div className="footer-buttons">
              <Button
                type="button"
                dataTest="btnCancelFormTest"
                disabled={formik.isSubmitting}
                onClick={() => toggleInit()}
                color="primaryOutlinedIcon outlined"
              >
                {t('cancel')}
              </Button>
              <Button type="submit" dataTest="btnSubmitFormTest" disabled={formik.isSubmitting}>
                {t('next')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  )
}

export const ArtistForm = withTranslation()(Form)
