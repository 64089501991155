// import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useRef, useState } from 'react'
import './App.scss'

import Header from 'NewVersion/components/Navigation/Header'
import { BrowserRouter } from 'react-router-dom'
import ScrollTop from './Components/HelperComponents/ScrollTop'
import Footer from './NewVersion/components/Navigation/Footer'

import { Sidebar } from 'NewVersion/components/Navigation/Sidebar'
import { useOnClickOutside, useScrollPosition } from 'hooks'
import { slide as Menu } from 'react-burger-menu'
import { getApiCallPloneGeneric, guillotinaActionGeneric } from 'utils/apiCalls'
import { sendNotification } from './push-notifications'
import { useInterval } from './useInterval'

import { isMobile } from 'react-device-detect'
import SidebarElements from './Components/SidebarElements'

// import backgroundImage from './Statics/background_image_pwa.webp'

import { rootReducer } from './Reducers/root'
import { Routes } from './Routes/Routes'
import { getEmailFromToken } from './utils/utils'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/system'
import { MobileHeaderOptions } from 'NewVersion/components/Navigation/MobileHeaderOptions'
import { EditionContextProvider } from 'context/EditionContext'
import { YearsContextProvider } from 'context/YearsContext'
export const AuthContext = React.createContext()

const theme = createTheme()

function Main() {
  const node = useRef()
  useOnClickOutside(node, () => onSetSidebarOpen(false))
  const [sidebarOpen, onSetSidebarOpen] = useState(false)
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false)
  const [sticky, setSticky] = useState(true)
  const { authState } = React.useContext(AuthContext)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== sticky && window.pageYOffset > 84) {
        setSticky(isShow)
      }
      if (isShow !== sticky && currPos.y === 0) {
        setSticky(true)
      }
    },
    [sticky]
  )

  return (
    <div className="App" id="App">
      <div ref={node}>
        <Menu noOverlay disableCloseOnEsc isOpen={sidebarOpen} right>
          <SidebarElements onSetSidebarOpen={onSetSidebarOpen} />
        </Menu>
      </div>
      <Header mobileHeaderOpen={mobileHeaderOpen} setMobileHeaderOpen={setMobileHeaderOpen} />
      <MobileHeaderOptions
        isOpen={mobileHeaderOpen && authState.isAuthenticated}
        setIsOpen={setMobileHeaderOpen}
      />
      <div
        className={`${!sticky ? 'page-content content scroll' : 'page-content content'} ${
          authState.isAuthenticated ? 'autenticated' : ''
        }`}
        style={
          authState.isAuthenticated || isMobile
            ? {}
            : {
                animation: 'fadeBackground 0.5s',
                bacgkroundColor: 'white',
                // backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }
        }
        id="content"
      >
        <Sidebar isMobile={false} />
        <ScrollTop />
        <Routes />
      </div>
      <Footer />
    </div>
  )
}

function App() {
  const [authState, dispatch] = React.useReducer(rootReducer, {
    isAuthenticated: localStorage.isAuthenticated ?? false,
    user: localStorage.user ?? null,
    token: localStorage.token ?? null,
    edition: localStorage.edition ? JSON.parse(localStorage.edition) : ['2024', ''],
  })

  useInterval(() => {
    if (localStorage.isAuthenticated) {
      guillotinaActionGeneric({
        method: 'GET',
        action: getEmailFromToken(),
        setCallBack: localNotificationsHandler,
      })
    }
  }, 300000) // Cada 5 minuts hauria de ser 300000

  const localNotificationsHandler = (response) => {
    localNotifications(response.data.preferits)
  }
  const localNotifications = (favourites) => {
    favourites.forEach(function (fav) {
      getApiCallPloneGeneric({
        path: JSON.parse(localStorage.edition)[1] + '@search',
        finishCallBack: pushNotificationHandlerData,
        params: { UID: fav },
        sort: false,
      })
    })
  }

  const pushNotificationHandlerData = (response) => {
    let tmpArray = []
    if (response.status === 200) {
      if (response.data.items.length !== 0) {
        tmpArray = response.data.items[0]
      }
    }
    pushNotificationHandler(tmpArray)
  }

  const pushNotificationHandler = (element) => {
    if (element.data) {
      const now = Date.now()
      const showDate = Date.parse(element.data)
      JSON.parse(localStorage.edition)
      if (
        showDate - now > 0 &&
        showDate - now <= 3600000 &&
        !JSON.parse(localStorage.notificatedUID).includes(element.UID)
      ) {
        const arr = JSON.parse(localStorage.notificatedUID)
        arr.push(element.UID)
        localStorage.setItem('notificatedUID', JSON.stringify(arr))
        sendNotification(element.title)
      }
    }
  }

  return (
    <AuthContext.Provider value={{ authState, dispatch }}>
      <EditionContextProvider>
        <YearsContextProvider>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Main />
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </YearsContextProvider>
      </EditionContextProvider>
    </AuthContext.Provider>
  )
}

export default App
