import { getCurrentLanguage } from '../utils/utils'
export const rootInitialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  edition: ['2024', ''],
}

export const rootReducer = (state, action) => {
  switch (action.type) {
    case 'EDITION':
      localStorage.setItem('edition', JSON.stringify([action.payload.edition, action.payload.url]))
      return {
        ...state,
        edition: [action.payload.edition, action.payload.url],
      }
    case 'LOGIN':
      localStorage.setItem('user', action.payload.user.toLowerCase())
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('isAuthenticated', true)
      localStorage.setItem('edition', JSON.stringify([state.edition[0], state.edition[1]]))
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      }
    case 'LOGOUT':
      const i18nextLng = getCurrentLanguage()
      localStorage.clear()
      localStorage.setItem('i18nextLng', i18nextLng)

      return rootInitialState
    default:
      return state
  }
}
